import { PriceRelationExtend } from "~/dto/PriceRelationDTO";
import { ProductEligibleDTO } from "~/dto/product/ProductEligibleDTO";
import { ProductFormatExtend } from "./ProductFormatDTO";
import { ProductEpassDTO } from "./ProductEpassDTO";

export enum BookingStatus {
  complete = "Plus de produit disponible",
  waitingList = "Complet, mais une liste d'attente est disponible",
  available = "Il reste des produits",
  tooAdvanced = "Trop avancé",
  epass = "Réservable par carte de cours",
}

export type GlobalProduct = ProductDTO | ProductExtend | ProductFormatExtend | ProductEpassDTO;

export enum productType {
  membership = "membership",
  license = "license",
  inscription = "inscription",
  clothing = "clothing",
  epass = "epass",
  format = "format",
  additionalProduct = "product",
  participant = "participant",
}

export interface IProduct {
  id: number;
  orgaId: number;
  draft: boolean;
  title: string;
  type: string;
  quantity: number;
  ageMin: number;
  ageMax: number;
  sold: number;
  dateValidity: string;
  description: string;
  scope: string;
  restriction: string;
  urlPage: string;
  date: string;
  archiveId: number;
  deleted: number;
}

export class ProductDTO {
  public id = 0;
  public orgaId = 0;
  public draft = false;
  public title = "";
  public type = "";
  public quantity = 0;
  public ageMin = 0;
  public ageMax = 0;
  public sold = 0;
  public dateValidity = "";
  public description = "";
  public scope = "";
  public position = 0;
  public restriction = "unrestricted";
  public urlPage = "";
  public date = "";
  public archiveId = 0;
  public deleted = 0;
  public trial = false;
  public modalityOption = "follow";
  public paymentTypeOption = "follow";

  constructor(init?: Partial<ProductDTO>) {
    if (init === undefined) return;
    Object.assign(this, init);
  }
}

export class ProductExtend extends ProductDTO {
  // PRICE
  public priceRelations: PriceRelationExtend[] = [];

  // ELIGIBLE
  public eligibles: ProductEligibleDTO[] = [];
  public eligibleType = "";

  // RESTRICTION
  static UNRESTRICTED = "unrestricted";
  static RESTRICTED_ALL_USERS = "restricted-all-users";
  static RESTRICTED_SELECTED_USERS = "restricted-selected-users";

  constructor(init?: Partial<ProductExtend>) {
    super();
    if (init === undefined) return;
    Object.assign(this, init);
  }
}
